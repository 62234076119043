import { useMediaQuery } from '/machinery/useCachingMediaQuery'

import { useItemUrl } from '/features/regionArticles/useItemUrl'
import { useCardAnimations } from '/features/regionArticles/cards/useCardAnimations'
import { CardWithGradientBackgroundCyan, CardPlusIcon } from '/features/regionArticles/cards/Card'
import { CardImage, CardImageNonCover } from '/features/regionArticles/cards/CardImage'
import { HeadingGroupSnackables } from '/features/buildingBlocks/HeadingGroup'

import mediaStyles from '/cssGlobal/media.css'
import styles from './CardKunstcollectie.css'

export function CardKunstcollectie({ item, layoutClassName }) {
  const { content, metadata } = item
  const { title, image } = content.hero
  const { rubric } = metadata
  const url = useItemUrl(item)


  return (
    <CardWithGradientBackgroundCyan contentContainerClassName={styles.component} {...{ layoutClassName }}>
      <Content rubric={rubric.name} layoutClassName={styles.contentLayout} {...{ title, image, url }} />
    </CardWithGradientBackgroundCyan>
  )

}

function Content({ title, rubric, image, url, layoutClassName }) {
  const animations = useCardAnimations()
  const isViewportMd = useMediaQuery(mediaStyles.viewportMd)

  return (
    <div className={cx(styles.componentContent, layoutClassName)}>
      {image && (
        <div className={cx(styles.imageBox, styles.imageBoxLayout)}>
          <div inert={!isViewportMd && 'true'} className={styles.imageContainerMobile}>
            <CardImageNonCover
              animation={animations.imageScale}
              layoutClassName={styles.imageLayout}
              {...{ image }}
            />
          </div>
          <div inert={isViewportMd && 'true'} className={styles.imageContainerDesktop}>
            <CardImage
              aspectRatio={5 / 4}
              animation={animations.imageScale}
              layoutClassName={styles.imageLayout}
              {...{ image }}
            />
          </div>
          <div className={styles.plusIconContainer}>
            <CardPlusIcon layoutClassName={styles.plusIconLayout} />
          </div>
        </div>
      )}
      <a href={url} draggable='false' className={styles.kunstcollectieLink}>
        <HeadingGroupSnackables
          subtitle={rubric}
          animation={animations.headingGroup}
          {...{ title }}
        />
      </a>
    </div>
  )
}
